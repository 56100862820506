// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$(),
  _s3 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { json } from '@remix-run/node';
import { isRouteErrorResponse, Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useLocation, useRouteError } from '@remix-run/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from '~/i18n.server';
import reset from '~/styles/reset.css';
import fonts from '~/styles/fonts.css';
import { cssBundleHref } from '@remix-run/css-bundle';
import styles from './root.module.css';
import { Footer } from './components/Footer';
import { ErrorPage } from './components/ErrorPage';
import { usePrefixedTranslation } from './hooks/usePrefixedTranslation';
import { CONFIRMATION_PAGE_ERROR } from './routes/confirmation';
import { logger } from 'lib/logger';
import { isProduction } from '@bodycoach/lib/lambda/config/environment';
import { Maintenance } from './components/Maintenance';
import '@preact/signals-react/auto';
function useChangeLanguage(locale) {
  _s();
  const {
    i18n
  } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale, i18n]);
}
_s(useChangeLanguage, "XIFq83ieJr2IDBjDrSkk+Qj6jto=", false, function () {
  return [useTranslation];
});
export async function loader({
  request
}) {
  const locale = await i18next.getLocale(request);
  return json({
    locale,
    baseUrl: process.env.BASE_URL,
    isProd: isProduction(process.env.ENVIRONMENT || ''),
    maintenance: !!process.env.MAINTENANCE,
    googleTagManagerId: process.env.GOOGLE_TAG_MANAGER_ID,
    googleTagManagerStageAuth: process.env.GOOGLE_TAG_MANAGER_STAGE_AUTH || '',
    googleTagManagerStagePreviewEnv: process.env.GOOGLE_TAG_MANAGER_STAGE_PREVIEW_ENV || ''
  });
}
export const links = () => {
  return [{
    rel: 'stylesheet',
    href: reset
  }, {
    rel: 'stylesheet',
    href: fonts
  }, {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '/apple-touch-icon.png'
  }, {
    rel: 'icon',
    type: 'img/png',
    sizes: '32x32',
    href: '/favicon-32x32.png'
  }, {
    rel: 'icon',
    type: 'img/png',
    sizes: '16x16',
    href: '/favicon-16x16.png'
  }, {
    rel: 'manifest',
    href: '/site.webmanifest'
  }, ...(cssBundleHref ? [{
    rel: 'stylesheet',
    href: cssBundleHref
  }] : [])];
};
export const meta = ({
  data
}) => data?.isProd ? [] : [{
  name: 'robots',
  content: 'noindex'
}];
export const handle = {
  i18n: 'translation'
};
function errorShouldLog(err) {
  if (isRouteErrorResponse(err) && err.status >= 400 && err.status < 500) {
    return false;
  }
  return true;
}
export function ErrorBoundary() {
  _s2();
  const err = useRouteError();
  if (errorShouldLog(err)) {
    if (typeof window !== 'undefined') {
      window.cwr?.('recordError', err);
    } else {
      logger.error({
        err
      }, 'Web subscriptions error');
    }
  }
  const {
    t
  } = usePrefixedTranslation('error');
  let title = t('generic.title');
  let message = t('generic.message');
  let showSupportContact = false;
  if (isRouteErrorResponse(err)) {
    switch (err.status) {
      case 404:
        title = t('notFound.title');
        message = t('notFound.message');
        break;
      case 500:
        if (err.data === CONFIRMATION_PAGE_ERROR) {
          title = t('payment.title');
          message = t('payment.message');
          showSupportContact = true;
        }
        break;
    }
  }
  return <html>
      <head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className={styles.root}>
        <div className={styles.page}>
          <ErrorPage title={title} message={message} showSupportContact={showSupportContact} />
        </div>

        <Footer />

        <Scripts />
        <script src="/rum.js" async={true}></script>
      </body>
    </html>;
}
_s2(ErrorBoundary, "WBG170qbopO/a7ZQq4l3W894Acg=", false, function () {
  return [useRouteError, usePrefixedTranslation];
});
_c = ErrorBoundary;
export default function App() {
  _s3();
  const {
    locale,
    maintenance,
    googleTagManagerId,
    baseUrl,
    isProd,
    googleTagManagerStageAuth,
    googleTagManagerStagePreviewEnv
  } = useLoaderData();
  const {
    i18n
  } = useTranslation();
  const location = useLocation();
  console.log('hello');
  useChangeLanguage(locale);
  const googleTagManagerScript = isProd && googleTagManagerId ? `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${googleTagManagerId}');` : `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${googleTagManagerStageAuth}&gtm_preview=${googleTagManagerStagePreviewEnv}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${googleTagManagerId}');`;
  return <html lang={locale} dir={i18n.dir()}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />

        <link rel="canonical" href={`${baseUrl}${location.pathname}`} />

        {/* This brings up a console warning however at this time there is no nice fix and we are doing what remix docs recomend todo.
          More info can be found in these docs - https://github.com/remix-run/examples/blob/main/google-analytics/app/root.tsx */}
        {googleTagManagerId ? <script dangerouslySetInnerHTML={{
        __html: googleTagManagerScript
      }}>
        </script> : null}

        {/**
          * To test analytics locally append `.local` to the script name and
          * adjust the `data-domain` value to point at the URL for the app in
          * your environment (e.g. `[name]-subscribe.thebodycoach.com`).
          *
          * You'll need this domain to be set up as a site in Plausible too.
          *
          * @see https://plausible.io/docs/script-extensions
          */}
        <script defer={true} data-domain={baseUrl?.replace('https://', '')} src="https://plausible.io/js/script.outbound-links.tagged-events.revenue.js">
        </script>
      </head>
      <body className={styles.root}>
        {/**
          * If `maintenance` is set to true we enter maintenance mode where no
          * page content is available and the user can't interact with the app.
          */}
        <div className={styles.page}>{maintenance ? <Maintenance /> : <Outlet />}</div>

        <Footer />

        <ScrollRestoration />
        <Scripts />
        <script src="/rum.js" async={true}></script>
        <LiveReload />
      </body>
    </html>;
}
_s3(App, "cN2GQcGmE/571U+HNAT+9qDFiuA=", false, function () {
  return [useLoaderData, useTranslation, useLocation, useChangeLanguage];
});
_c2 = App;
var _c, _c2;
$RefreshReg$(_c, "ErrorBoundary");
$RefreshReg$(_c2, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;